<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="90"
      round
      :needBtnLoading="true"
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
    </GlobalForm>
  </div>
</template>

<script>
import { formValidateMobile } from "@/utils/form-validate";
import moment from 'moment'

import _api from "@/utils/request";
export default {
  name: "Form",
  props: {
    accountType: {
      require: true,
      type: String
    },
  },
  data() {
    return {
      initData: {
        entryTime: [
          moment()
            .day(moment().day()-6)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ],
      },
      formItemList: [
        {
          key: 'bizType',
          type: 'select',
          labelName: '类型',
          placeholder: '请选择类型',
          option: [
            {
              value: '01',
              label: '付款到用户',
            },
            {
              value: '02',
              label: '个人提现',
            },
            {
              value: '03',
              label: '公司提现',
            },
            {
              value: '04',
              label: '领取返佣',
            },
          ],
        },
        {
          key: "transferNo",
          type: "input",
          labelName: "出账单号",
          placeholder: "请输入出账单号",
        },
        {
          key: "relationNo",
          type: "input",
          labelName: "关联订单号",
          placeholder: "请输入关联订单号",
        },
        {
          key: "staffName",
          type: "input",
          labelName: "姓名",
          placeholder: "请输入姓名",
        },
        {
          key: 'staffRole',
          type: 'select',
          labelName: '角色',
          placeholder: '请选择角色',
          option: [
            {
              value: '01',
              label: '创建人',
            },
            {
              value: '02',
              label: '总监',
            },
            {
              value: '03',
              label: '店长',
            },
            {
              value: '04',
              label: '店员',
            },
            {
              value: '05',
              label: '用户',
            },
            {
              value: '06',
              label: '子账号',
            },
          ],
        },
        {
          key: "staffPhone",
          type: "input",
          labelName: "电话",
          placeholder: "请输入电话",
        },
        {
          key: "entryTime",
          type: "pickerOptions",
          labelName: "创建时间",
          placeholder: "请选择创建时间",
          valueFormat: "timestamp",
          clearable: true,
        },
        {
          key: 'auditType',
          type: 'select',
          labelName: '是否需审',
          placeholder: '请选择是否需审',
          option: [
            {
              value: '00',
              label: '免审核',
            },
            {
              value: '01',
              label: '需审核',
            }
          ],
        },
        {
          key: "companyName",
          type: "input",
          labelName: "所属商家",
          placeholder: "请输入所属商家",
        },
        {
          key: 'transferChannel',
          type: 'select',
          labelName: '收款方式',
          placeholder: '请选择收款方式',
          option: [
            {
              value: '',
              label: '全部',
            },
            {
              value: 'wechat',
              label: '微信',
            },
            {
              value: 'alipay',
              label: '支付宝',
            }
          ],
        },
      ],
    };
  },
  created() {
    this.handleConfirm(this.initData)
  },
  watch:{
  },
  methods: {
    handleConfirm(data,cd) {
      console.log("提交了form", data);
      let SeachParams = {};
      if (data) {
        SeachParams = {
          bizType: data.bizType,
          transferNo: data.transferNo,
          relationNo: data.relationNo,
          staffName: data.staffName,
          staffRole: data.staffRole,
          staffPhone: data.staffPhone,
          auditType: data.auditType,
          transferChannel: data.transferChannel,
          companyName: data.companyName,
          startTime: data.entryTime ? moment(data.entryTime[0]).format("x") : "",
          endTime: data.entryTime ? moment(data.entryTime[1]).format("x") : "",
          pageNum: 1,
          pageSize: 10,
        };
      }
      SeachParams. disuseLoding= () => {
          if(typeof cd ==='function'){
             cd();
          }
        },

      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
}
</style>
