<template>
  <div class="class-list">
    <GlobalInfoBar
      title="微信账户交易"
      content="管理微信账户的出/入账交易记录及提现（包括: 用户收款、用户退款、员工领取佣金、红包提现、商家余额提现"
    />
    <!-- <div class="button_top">
      <div class="radiobtn">
        <div class="faultBtn">
            <div v-bind:class="this.clickShow=== '01'? 'left' : 'right'" @click="BtnClick('active')">
              出账({{statisticsNum.outNum || 0}})
            </div>
            <div v-bind:class="this.clickShow=== '02'? 'left' : 'right'" @click="BtnClick('before')">
              入账({{statisticsNum.entranceNum || 0}})
            </div>
        </div>
      </div>
    </div> -->  
    <div class="button_top">
      <div class="faultBtn1">
          <div v-bind:class="this.clickShow=== '01'? 'left' : 'right'" @click="BtnClick('active')">
            出账({{statisticsNum.outNum || 0}})
          </div>
          <div v-bind:class="this.clickShow=== '02'? 'left' : 'right'" @click="BtnClick('before')">
            入账({{statisticsNum.entranceNum || 0}})
          </div>
      </div>
    </div>

    <div class="button_top" v-if="clickShow =='01'">
      <div class="radiobtn">
        <div class="faultBtn">
            <div v-bind:class="payClickShow=== '01'? 'left' : 'right'" @click="BtnPayClick('active')">
              付款
            </div>
            <div v-bind:class="payClickShow=== '02'? 'left' : 'right'" @click="BtnPayClick('before')">
              退款
            </div>
        </div>
      </div>
    </div>
    <!-- <Info/> -->
    <GlobalChunk icon="search" title="筛选查询" v-if="clickShow=='01' && payClickShow == '01'">
      <div slot="filter">
        <transferFrom />
      </div>
      <transferTable />
    </GlobalChunk>
    <GlobalChunk icon="search" title="筛选查询" v-if="clickShow=='01' && payClickShow == '02'">
      <div slot="filter">
        <payFrom />
      </div>
      <payTable />
    </GlobalChunk>
    <GlobalChunk icon="search" title="筛选查询" v-if="clickShow=='02'">
      <div slot="filter">
        <refundListFrom />
      </div>
      <refundListTable />
    </GlobalChunk>
  </div>
</template>

<script>
import transferFrom from "./transferList/Form";
import transferTable from "./transferList/Table";
import refundListFrom from "./refundList/Form";
import refundListTable from "./refundList/Table";
import payFrom from "./pay/Form";
import payTable from "./pay/Table";
import _api from "@/utils/request";
export default {
  name: "class-list",
  components: {
    transferFrom,
    transferTable,
    refundListFrom,
    refundListTable,
    payFrom,
    payTable
  },
  data() {
    return {
        clickShow: '01',
        payClickShow:'01',
        statisticsNum: {}
    };
  },
  created(){
    this.$store.commit("tagsView/SEACH_PARAMS", {})
    this.getStatisticsNum()
  },
  methods: {
    BtnClick(type){
      this.$store.commit("tagsView/SEACH_PARAMS", {});
      this.getStatisticsNum()
      if(type==='active'){
        this.clickShow='01'
        this.payClickShow = '01'
      }else{
        this.clickShow='02'
         this.payClickShow = '01'
      }
    },
    BtnPayClick(type){
      this.$store.commit("tagsView/SEACH_PARAMS", {});
      if(type==='active'){
        this.payClickShow = '01'
      }else{
         this.payClickShow = '02'
      }
    },
    getStatisticsNum(){
      _api.statisticsNum().then(res => {
        console.log(res);
        if(res.code === 1){
          this.statisticsNum = res.data
        }
      })
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.class-list{
  .button_top{
    cursor: pointer;
    margin-bottom: 20px;;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .faultBtn{
    width: 280px;
    height: 32px;
    background: #F9FBFD;
    border: 1px solid #C1D1FF;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    .left{
      text-align: center;
      color: white;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 30px;
      height: 30px;
      background: #0981FF;
      border-radius: 20px;
    }
    .right{
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 30px;
      height: 30px;
      border-radius: 20px;
    }
  }

  .faultBtn1{
    width: 280px;
    height: 32px;
    display: flex;
    .left{
      position: relative;
      text-align: center;
      color: #0981FF;
      width: 50%;
      height: 14px;
      font-size: 16px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 700;
      line-height: 30px;
      height: 30px;
      border-radius: 20px;
      &::before {
        content: "";
        width: 55px;
        height: 3px;
        border-radius: 1px;
        background-color: #5a8eff;
        position: absolute;
        top: 32px;
        left: 30%;
      }
    }
    .right{
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 30px;
      height: 30px;
      border-radius: 20px;
    }
  }
}
</style>